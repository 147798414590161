import React, { useEffect, useState, useCallback } from 'react';
import { Box, Table, TableBody, TableRow, TableHead, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';
import { showTimeInAgoFormat } from '../../Utils/Time';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ReportedQuestions() {
    const [reports, setReports] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const fetchReports = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/question-ops/v1/report`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            if (data.status === 200) {
                setReports(data.data.list);
            }
        } catch (error) {
            console.error('Error fetching reports:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleQuestionClick = (questionID) => {
        navigate(`/edit-question?id=${questionID}`);
    };

    const handleMarkResolved = async (reportId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/question-ops/v1/resolve-report/${reportId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ resolved: true }),
            });
            const data = await response.json();
            if (data.status === 200) {
                fetchReports()
            }
        } catch (error) {
            console.error('Error marking report as resolved:', error);
        }
    };

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h4" sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                    Reported Questions
                </Typography>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <Table sx={{ width: '100%', marginBottom: 30, marginTop: 5 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S.No</StyledTableCell>
                                <StyledTableCell>User</StyledTableCell>
                                <StyledTableCell>Question</StyledTableCell>
                                <StyledTableCell>Remark</StyledTableCell>
                                <StyledTableCell>Reported At</StyledTableCell>
                                <StyledTableCell>Mark Resolved</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports.map((report, index) => (
                                <StyledTableRow key={report._id}>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell>{report.user || 'N/A'}</StyledTableCell>
                                    <StyledTableCell sx={{ cursor: 'pointer' }} onClick={() => handleQuestionClick(report.question._id)}>
                                        {report.question?.question || 'N/A'}
                                    </StyledTableCell>
                                    <StyledTableCell>{report.remark}</StyledTableCell>
                                    <StyledTableCell>{showTimeInAgoFormat(report.reportedAt)}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton onClick={() => handleMarkResolved(report._id)} color="primary">
                                            <CheckIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
        </Box>
    );
}

export default ReportedQuestions;
