import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';

const ImageUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedUrl, setUploadedUrl] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post('https://api.studencity.in/api/wagroup/upload/image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response : ', response)

            setUploadedUrl(response.data.data.imageId);
        } catch (error) {
            console.error('Error uploading the file', error.response);
        }
    };


    const handleCopyUrl = () => {
        if (uploadedUrl) {
            navigator.clipboard.writeText(uploadedUrl)
                .then(() => {
                    alert('URL copied to clipboard');
                })
                .catch((err) => {
                    console.error('Failed to copy the URL: ', err);
                });
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <div className="w-full my-10 p-6 bg-white shadow-lg rounded-lg">
                        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                            UPLOAD IMAGE
                        </Typography>
                        {
                            !uploadedUrl && <>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    className="block w-full text-sm text-gray-500
                   file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-indigo-50 file:text-indigo-700
                   hover:file:bg-indigo-100
                   mb-4"
                                />
                                <button
                                    onClick={handleUpload}
                                    className="bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition duration-300"
                                >
                                    Upload
                                </button>
                            </>
                        }

                        {uploadedUrl && (
                            <div className="mt-4">
                                <button
                                    onClick={() => { window.location.reload() }}
                                    className="bg-indigo-600 text-white py-2 px-4 my-4 rounded hover:bg-indigo-700 transition duration-300"
                                >
                                    Upload More
                                </button>
                                <a href={uploadedUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 underline flex flex-wrap">
                                    {uploadedUrl}
                                </a>
                                <button
                                    onClick={handleCopyUrl}
                                    className="my-4 bg-gray-200 text-gray-700 py-1 px-3 rounded hover:bg-gray-300 transition duration-300"
                                >
                                    Copy URL
                                </button>
                                <img src={uploadedUrl} alt="Uploaded" className="mt-2 max-w-full h-auto rounded-3xl" />
                            </div>
                        )}
                    </div>
                </Box>
            </Box>
        </Box>

    );
};

export default ImageUpload;
