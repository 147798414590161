import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import axios from 'axios';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';

const data = [
    { name: 'Jan', pv: 2400 },
    { name: 'Feb', pv: 1398 },
    { name: 'Mar', pv: 9800 },
    { name: 'Apr', pv: 3908 },
    { name: 'May', pv: 4800 },
    { name: 'Jun', pv: 3800 },
    { name: 'Jul', pv: 4300 }
];

function Home() {
    const [questionCount, setQuestionCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [quizCount, setQuizCount] = useState(0);
    const [classCount, setClassCount] = useState(0)
    const [subjectsCount, setSubjectCount] = useState(0)
    const [adminCount, setAdminCount] = useState(0);
    const [mailsSentCount, setMailsSentCount] = useState(0);
    const [apiCallsCount, setApiCallsCount] = useState(0)
    const [quizSubmissionsCount, setQuizSubmissionsCount] = useState(0)
    const [mediaUploadCount, setMediaUploadCount] = useState(0)

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stats/collection-count`)
            .then(response => {
                const data = response.data.data.list;
                console.log('data : ', data)
                setQuestionCount(data.Question);
                setUsersCount(data.User);
                setQuizCount(data.Quiz);
                setAdminCount(data.adminCount);
                setClassCount(data.Class)
                setSubjectCount(data.Subject)
                setMailsSentCount(data.mailsSentCount)
                setApiCallsCount(data.RequestLog)
                setQuizSubmissionsCount(data.QuizSubmission)
                setMediaUploadCount(data.mediaUploadCount)
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);

    const analysisPoints = [
        {
            label: 'Questions',
            value: questionCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/405420_624084.png'
        },
        {
            label: 'Users',
            value: usersCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/212209_449602.png',
        },
        {
            label: 'Classes',
            value: classCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/405420_624084.png'
        },
        {
            label: 'Subjects',
            value: subjectsCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/896099_357473.png'
        },
        {
            label: 'Quizzes',
            value: quizCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/896099_357473.png'
        },
        {
            label: 'API Calls',
            value: apiCallsCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/283055_55927.png'
        },
        {
            label: 'Quiz Submissions',
            value: quizSubmissionsCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/614112_963678.png'
        },
    ];

    return (
        <div className="flex">
            <DrawerNav />
            <div className="flex-grow p-6">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {analysisPoints.map((point, index) => (
                        <div key={index} className="cursor-pointer bg-white shadow-lg rounded-lg p-6 flex flex-col transition transform hover:scale-105">
                            <div className="flex items-center">
                                <img src={point.icon} alt={point.label} className="w-14 h-14 mr-4" />
                                <div>
                                    <h2 className="text-2xl font-semibold text-gray-600">{point.label}</h2>
                                    <p className="text-xl text-gray-600 mt-2">{point.value}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Home;
