import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { BASE_URL } from "../../Api/Constants";
import { useNavigate } from "react-router-dom";
import DrawerNav from "../Navigation/DrawerNav";
import { SHOW_TOAST } from "../../Notifications/Toast";

export default function AddClass() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [imageURL, setImageURL] = useState("");
  const navigate = useNavigate();

  const handleSaveChanges = async () => {
    if (!name || !description) {
      SHOW_TOAST("All fields are required", "error");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/v1/resources/class`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          description,
          imageURL,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        SHOW_TOAST("Class added successfully", "success");
        setName("");
        setDescription("");
        setImageURL("");
        navigate(-1);
      } else {
        SHOW_TOAST(data.message || "Failed to add Exam", "error");
      }
    } catch (error) {
      SHOW_TOAST("Error: Failed to add Exam", "error");
      console.error("Error:", error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerNav />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Box p={2}>
          <Typography
            variant="h4"
            sx={{ my: 2, fontWeight: "bolder", color: "#4b4848" }}
          >
            Add New Exam
          </Typography>
          <TextField
            label="Exam Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Exam Description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Exam Image URL"
            value={imageURL}
            onChange={(event) => setImageURL(event.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveChanges}
            >
              Add Exam
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
