import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { DashboardTwoTone, DashboardRounded } from "@mui/icons-material";
import { navItems, logoutItem } from "./NavItems";

const Header = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [clickCount, setClickCount] = React.useState(() => {
    // Initialize from localStorage if available
    const savedCount = localStorage.getItem("headerClickCount");
    return savedCount ? parseInt(savedCount, 10) : 0;
  });
  const [firstClickTime, setFirstClickTime] = React.useState(() => {
    const savedTime = localStorage.getItem("firstClickTime");
    return savedTime ? parseInt(savedTime, 10) : null;
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleRoute = (url) => {
    navigate("/" + url);
    setAnchorElNav(null);
  };

  const handleHeaderClick = () => {
    const currentTime = Date.now();
    if (firstClickTime && currentTime - firstClickTime > 5000) {
      setClickCount(1);
      setFirstClickTime(currentTime);
      localStorage.setItem("headerClickCount", 1);
      localStorage.setItem("firstClickTime", currentTime);
    } else {
      // Otherwise, increment the counter
      const newCount = clickCount + 1;
      setClickCount(newCount);
      localStorage.setItem("headerClickCount", newCount);

      if (newCount === 1) {
        setFirstClickTime(currentTime);
        localStorage.setItem("firstClickTime", currentTime);
      }

      if (newCount === 5) {
        navigate("/logs");
        setClickCount(0);
        setFirstClickTime(null);
        localStorage.removeItem("headerClickCount");
        localStorage.removeItem("firstClickTime");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <AppBar position="sticky">
      <Container
        maxWidth="100%"
        sx={{
          backgroundColor: "#3c3f44",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar disableGutters onClick={handleHeaderClick}>
          <img
            src="Exc_logo.png" // Logo source
            alt="Excomrade Logo"
            style={{ width: 60, height: 50, marginRight: 8 }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "calibri",
              fontWeight: 700,
              fontSize: 25,
              letterSpacing: ".15rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <span className="select-none">Excomrade Admin</span>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {navItems.map((item) => (
                <MenuItem
                  key={item.value}
                  onClick={() => handleRoute(item.route)}
                >
                  <Typography textAlign="center">{item.value}</Typography>
                </MenuItem>
              ))}
              <MenuItem
                key={logoutItem.value}
                onClick={() => handleRoute(logoutItem.route)}
              >
                <Typography textAlign="center">{logoutItem.value}</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Excomrade Admin
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
