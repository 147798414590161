import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { BASE_URL } from '../../Api/Constants';
import { Box, Typography } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';

const RequestLogs = () => {
    const [apiCalls, setApiCalls] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchApiCalls = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/v1/logs/request-logs`);
                const data = await response.json();
                console.log('data : ', data)
                setApiCalls(data.data.logs);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching API call details:', error);
                setLoading(false);
            }
        };

        fetchApiCalls();
    }, []);

    if (loading) {
        return <div className="text-center p-4">Loading...</div>;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box sx={{ flexGrow: 1, width: '100%' }}>
                <div className="container mx-auto p-4">
                    {apiCalls.map((call, index) => (
                        <ApiCallItem key={index} call={call} />
                    ))}
                </div>
            </Box>
        </Box>
    );
};

const ApiCallItem = ({ call }) => {
    const [showRequestBody, setShowRequestBody] = useState(false);

    const toggleRequestBody = () => {
        setShowRequestBody(!showRequestBody);
    };

    const getStatusCodeColor = (statusCode) => {
        if (statusCode >= 200 && statusCode < 400) {
            return 'bg-green-100'; // Light green for successful/redirect responses
        } else if (statusCode >= 400) {
            return 'bg-red-100'; // Light red for error responses
        } else {
            return 'bg-gray-100'; // Default color for other statuses
        }
    };

    const handleCopy = () => {
        if (call.requestBody) {
            navigator.clipboard.writeText(JSON.stringify(call.requestBody, null, 2));
        }
    };

    return (
        <div
            className={`border border-gray-300 rounded-lg mb-4 p-4 cursor-pointer ${getStatusCodeColor(call.statusCode)}`}
            onClick={toggleRequestBody}
        >
            <div className="flex justify-between items-center">
                <div>
                    <p className="font-bold text-lg">{call.method} {call.url}</p>
                    <p className="text-sm text-gray-600">Status Code: {call.statusCode}</p>
                    <p className="text-xs text-gray-500">{formatDistanceToNow(new Date(call.timestamp))} ago</p>
                </div>
            </div>
            {showRequestBody && call.requestBody && (
                <div className="mt-4 p-4 bg-white border-t border-gray-300">
                    <div className="flex justify-between items-center">
                        <p className="font-semibold">Request Body:</p>
                    </div>
                    <pre className="p-2 rounded overflow-auto" onClick={handleCopy}>
                        {JSON.stringify(call.requestBody, null, 2)}
                    </pre>
                </div>
            )}
        </div>

    );
};

export default RequestLogs;
