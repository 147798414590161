// src/navItems.js

import {
  Home,
  Search,
  Logout,
  Image,
  Folder,
  Cases,
  Subject,
  Topic,
  Category,
  QuestionAnswer,
  QuestionMark,
  Report,
} from "@mui/icons-material";

export const navItems = [
  {
    value: "Home",
    route: "",
    icon: <Home />,
  },
  {
    value: "Image Upload",
    route: "image-upload",
    icon: <Image />,
  },
  {
    value: "Search Question",
    route: "search-question",
    icon: <Search />,
  },
  {
    value: "Add Exam",
    route: "add-class",
    icon: <Folder />,
  },
  {
    value: "Quiz Submissions",
    route: "quiz-submissions",
    icon: <QuestionMark />,
  },
  {
    value: "Reported Questions",
    route: "reported-questions",
    icon: <Report />,
  },
];

export const navItems2 = [
  {
    value: "Exam Management",
    route: "resources/class?parentName=",
    icon: <Subject />,
  },
  {
    value: "Subject Management",
    route: "management/subject",
    icon: <Subject />,
  },
  {
    value: "Quiz Management",
    route: "management/quiz",
    icon: <Topic />,
  },
];

export const logoutItem = {
  value: "Logout",
  route: "login",
  icon: <Logout />,
};
