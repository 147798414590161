import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Button, List, ListItem, ListItemText } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';

const ReorderQuestions = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/v1/quiz/${id}`);
                const data = await response.json();
                console.log('Data : ', data)
                if (data.status === 200) {
                    if (Array.isArray(data.data.resourceData.questions)) {
                        setQuestions(data.data.resourceData.questions);
                    }
                }
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, [id]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const updatedQuestions = Array.from(questions);
        const [reorderedItem] = updatedQuestions.splice(result.source.index, 1);
        updatedQuestions.splice(result.destination.index, 0, reorderedItem);

        setQuestions(updatedQuestions);
    };

    const handleSaveOrder = async () => {
        try {
            const reorderedQuestions = questions.map((question, index) => ({
                _id: question._id,
                order: index + 1,
            }));
            const response = await fetch(`${BASE_URL}/api/v1/questions/reorder`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ quizId: id, questions: reorderedQuestions }),
            });

            if (response.ok) {
                navigate(-1);
            }
        } catch (error) {
            console.error('Error saving order:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <h1 className="text-4xl font-bold">Reorder Quiz Questions</h1>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="questions">
                        {(provided) => (
                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                {questions.map((question, index) => (
                                    <Draggable key={question._id} draggableId={question._id} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{
                                                    marginBottom: '10px',
                                                    padding: '10px',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: snapshot.isDragging ? '#f0f0f0' : '#fff',
                                                    boxShadow: snapshot.isDragging ? '0 4px 12px rgba(0, 0, 0, 0.3)' : '0 1px 3px rgba(0, 0, 0, 0.2)',
                                                    transition: 'background-color 0.2s ease, box-shadow 0.2s ease',
                                                }}
                                            >
                                                <ListItemText primary={question.question} />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveOrder}
                    sx={{ mt: 3 }}
                >
                    Save Order
                </Button>
            </Box>
        </Box>
    );
};

export default ReorderQuestions;
