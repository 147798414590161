/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { showTimeInAgoFormat } from '../../Utils/Time';
import { Box, Button, Table, TableBody, TextField, TableRow, TableHead, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DrawerNav from '../Navigation/DrawerNav';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ResourceList() {
    const { resource } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const parentId = queryParams.get('parentId');
    const parentName = queryParams.get('parentName');

    const [resourceList, setResourceList] = useState([]);
    const [filteredResources, setFilteredResources] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    const getResourceList = useCallback(async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = await fetch(`${BASE_URL}/api/v1/resources/${resource}${parentId ? `?parentId=${parentId}` : ''}`, requestOptions);
            const data = await response.json();
            console.log(data);
            if (data.status === 200) {
                setResourceList(data.data.list);
                setFilteredResources(data.data.list);
            }
        } catch (error) {
            console.log('Error : ', error);
        } finally {
            setShowLoader(false);
        }
    }, [resource, parentId]);

    useEffect(() => {
        setShowLoader(true);
        getResourceList();
    }, [resource, parentId, getResourceList]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredResources(resourceList);
        } else {
            setFilteredResources(
                resourceList.filter(
                    (item) =>
                        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (item.description && item.description.toLowerCase().includes(searchQuery.toLowerCase()))
                )
            );
        }
    }, [searchQuery, resourceList]);

    const navigateToAddResource = () => {
        navigate(`/add/${resource}?parentId=${parentId}&parentName=${parentName}`);
    };

    const navigateToReorder = () => {
        navigate(`/reorder/${resource}?parentId=${parentId}&parentName=${parentName}`);
    };

    const handleResourceClick = (id, name) => {
        let nextResource;
        switch (resource) {
            case 'class':
                nextResource = 'subject';
                break;
            case 'subject':
                nextResource = 'quiz';
                break;
            case 'quiz':
                navigate(`/quiz/${id}?name=${name}`);
                break
            default:
                nextResource = null;
        }

        if (nextResource) {
            navigate(`/resources/${nextResource}?parentId=${id}&parentName=${name}`);
        }
    };

    const handleResourceModifications = (resourceData) => {
        navigate(`/edit/${resource}/${resourceData._id}?name=${resourceData.name}&description=${resourceData.description}&imageURL=${resourceData.imageURL}`);
    };

    const toggleVisibility = async (resourceId, isVisible) => {
        try {
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ hidden: !isVisible })
            };

            const response = await fetch(`${BASE_URL}/api/v1/resources-utility/visibility/${resource}/${resourceId}`, requestOptions);
            if (response.ok) {
                setResourceList(prevState =>
                    prevState.map(item =>
                        item._id === resourceId ? { ...item, hidden: !isVisible } : item
                    )
                );
            }
        } catch (error) {
            console.log('Error updating visibility: ', error);
        }
    };

    const getTitle = () => {
        return `${parentName} ${resource.charAt(0).toUpperCase() + resource.slice(1)} Management`;
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <div className='flex justify-between items-center p-4'>
                            <h1 className='text-4xl font-bold'>{getTitle()}</h1>
                            <div className='space-x-2 flex items-center'>
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    placeholder='Search...'
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    sx={{ height: '40px', backgroundColor: 'white' }}
                                />
                                <Button
                                    size='medium'
                                    variant='contained'
                                    color='success'
                                    onClick={navigateToAddResource}
                                    sx={{ height: '40px' }}
                                >
                                    Add {resource.charAt(0).toUpperCase() + resource.slice(1)}
                                </Button>
                                <Button
                                    size='medium'
                                    variant='contained'
                                    color='primary'
                                    onClick={navigateToReorder}
                                    sx={{ height: '40px' }}
                                >
                                    Reorder {resource.charAt(0).toUpperCase() + resource.slice(1)}
                                </Button>
                            </div>
                        </div>
                        <Table sx={{ width: '100%', marginBottom: 30, marginTop: 5 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='rounded-tl-lg' align='left'>
                                        S.No
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>{resource.charAt(0).toUpperCase() + resource.slice(1)}</StyledTableCell>
                                    <StyledTableCell align='left'>Description</StyledTableCell>
                                    <StyledTableCell align='left'>Updated</StyledTableCell>
                                    <StyledTableCell align='left'>Created</StyledTableCell>
                                    <StyledTableCell align='left'>Visibility</StyledTableCell>
                                    <StyledTableCell align='left'>Edit</StyledTableCell>
                                    <StyledTableCell align='left'>Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredResources.length > 0 &&
                                    filteredResources.map((item, index) => (
                                        <StyledTableRow key={item._id} sx={{ border: '1px solid gray' }}>
                                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                            <StyledTableCell
                                                align='left'
                                                className='underline cursor-pointer'
                                                onClick={() => handleResourceClick(item._id, item.name)}
                                            >
                                                {item.name}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>{item.description || 'No description'}</StyledTableCell>
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(item.updatedAt)}</StyledTableCell>
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(item.createdAt)}</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <IconButton onClick={() => toggleVisibility(item._id, item.hidden)}>
                                                    {item.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => handleResourceModifications(item)}
                                                    sx={{ mr: 2 }}
                                                >
                                                    Edit
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <Button
                                                    variant='contained'
                                                    color='error'
                                                    onClick={() => handleResourceModifications(item)}
                                                >
                                                    Delete
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </Box>
            </Box>
        </>
    );
}

export default ResourceList;
