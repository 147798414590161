/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { showTimeInAgoFormat } from '../../Utils/Time';
import { Box, Button, Table, TableBody, TableRow, TableHead, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DrawerNav from '../Navigation/DrawerNav';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function QuizSubmissions() {
    const navigate = useNavigate();
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchSubmissions = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/v2/submissions/quiz-submission`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            if (data.status === 200) {
                setSubmissions(data.data.list);
            }
        } catch (error) {
            console.error('Error fetching submissions:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchSubmissions();
    }, [fetchSubmissions]);

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h4" sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                    Quiz Submissions
                </Typography>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <Table sx={{ width: '100%', marginBottom: 30, marginTop: 5 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S.No</StyledTableCell>
                                <StyledTableCell>Username</StyledTableCell>
                                <StyledTableCell>Quiz Name</StyledTableCell>
                                <StyledTableCell>Score</StyledTableCell>
                                <StyledTableCell>Total</StyledTableCell>
                                <StyledTableCell>Submitted</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {submissions.map((submission, index) => (
                                <StyledTableRow key={submission._id}>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell>{submission.user?.username || 'N/A'}</StyledTableCell>
                                    <StyledTableCell>{submission.quiz?.name || 'N/A'}</StyledTableCell>
                                    <StyledTableCell>{submission.score}</StyledTableCell>
                                    <StyledTableCell>{submission.total}</StyledTableCell>
                                    <StyledTableCell>{showTimeInAgoFormat(submission.submittedAt)}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
        </Box>
    );
}

export default QuizSubmissions;
