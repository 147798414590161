import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Button, List, ListItem, ListItemText } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/Constants';

const ReorderResources = () => {
    const { resource } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const parentId = queryParams.get('parentId');
    const parentName = queryParams.get('parentName');

    const [resources, setResources] = useState([]);

    useEffect(() => {
        const fetchResources = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/v1/resources/${resource}${parentId ? `?parentId=${parentId}` : ''}`);
                const data = await response.json();
                if (data.status === 200) {
                    setResources(data.data.list);
                }
            } catch (error) {
                console.error('Error fetching resources:', error);
            }
        };

        fetchResources();
    }, [resource, parentId]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const updatedResources = Array.from(resources);
        const [reorderedItem] = updatedResources.splice(result.source.index, 1);
        updatedResources.splice(result.destination.index, 0, reorderedItem);

        setResources(updatedResources);
    };

    const handleSaveOrder = async () => {
        try {
            const reorderedIds = resources.map((resource) => resource._id);
            const response = await fetch(`${BASE_URL}/api/v1/resources-utility/reorder/${resource}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ reorderedIds })
            });

            if (response.ok) {
                navigate(`/resources/${resource}?parentId=${parentId}&parentName=${parentName}`);
            }
        } catch (error) {
            console.error('Error saving order:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <h1 className="text-4xl font-bold">Reorder {parentName} {resource.charAt(0).toUpperCase() + resource.slice(1)}</h1>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="resources">
                        {(provided) => (
                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                {resources.map((resource, index) => (
                                    <Draggable key={resource._id} draggableId={resource._id} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{
                                                    marginBottom: '10px',
                                                    padding: '10px',
                                                    borderBottom: '1px solid #ccc',
                                                    backgroundColor: snapshot.isDragging ? '#f0f0f0' : '#fff',
                                                    boxShadow: snapshot.isDragging ? '0 4px 12px rgba(0, 0, 0, 0.3)' : '0 1px 3px rgba(0, 0, 0, 0.2)',
                                                    transition: 'background-color 0.2s ease, box-shadow 0.2s ease',
                                                }}
                                            >
                                                <ListItemText primary={resource.name} />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveOrder}
                    sx={{ mt: 3 }}
                >
                    Save Order
                </Button>
            </Box>
        </Box>
    );
};

export default ReorderResources;
