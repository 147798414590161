import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { showTimeInAgoFormat } from '../../Utils/Time';
import { Box, Button, Table, TableBody, TextField, TableRow, TableHead, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DrawerNav from '../Navigation/DrawerNav';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ResourceManagement() {
    const { resource } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [resourceList, setResourceList] = useState([]);
    const [filteredResources, setFilteredResources] = useState([]);
    const [classes, setClasses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [showLoader, setShowLoader] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    const queryParams = new URLSearchParams(location.search);
    const parentId = queryParams.get('parentId');
    const parentName = queryParams.get('parentName');

    const getResourceList = useCallback(async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = await fetch(`${BASE_URL}/api/v2/resources/${resource}${parentId ? `?parentId=${parentId}` : ''}`, requestOptions);
            const data = await response.json();
            console.log(data);
            if (data.status === 200) {
                setResourceList(data.data.list);
                setFilteredResources(data.data.list);

                // Extract unique classes and subjects
                if (resource === 'subject' || resource === 'quiz') {
                    const uniqueClasses = [...new Set(data.data.list.map(item => item.class?.name))];
                    setClasses(uniqueClasses);
                }

                if (resource === 'quiz') {
                    const uniqueSubjects = [...new Set(data.data.list.map(item => item.subject?.name))];
                    setSubjects(uniqueSubjects);
                }
            }
        } catch (error) {
            console.log('Error : ', error);
        } finally {
            setShowLoader(false);
        }
    }, [resource, parentId]);

    useEffect(() => {
        setShowLoader(true);
        getResourceList();
    }, [resource, parentId, getResourceList]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredResources(resourceList);
        } else {
            setFilteredResources(
                resourceList.filter(
                    (item) =>
                        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (item.description && item.description.toLowerCase().includes(searchQuery.toLowerCase()))
                )
            );
        }
    }, [searchQuery, resourceList]);

    useEffect(() => {
        let filtered = resourceList;

        if (selectedClass && (resource === 'subject' || resource === 'quiz')) {
            filtered = filtered.filter(item => item.class?.name === selectedClass);
        }

        if (selectedSubject && resource === 'quiz') {
            filtered = filtered.filter(item => item.subject?.name === selectedSubject);
        }

        setFilteredResources(filtered);
    }, [selectedClass, selectedSubject, resource, resourceList]);

    const handleClassChange = (event) => {
        setSelectedClass(event.target.value);
        setSelectedSubject(''); // Reset subject when class changes
    };

    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
    };

    const handleNameClick = (id, name) => {
        let nextResource;
        switch (resource) {
            case 'class':
                nextResource = 'subject';
                break;
            case 'subject':
                nextResource = 'quiz';
                break;
            case 'quiz':
                navigate(`/quiz/${id}?name=${name}`);
                break
            default:
                nextResource = null;
        }

        if (nextResource) {
            navigate(`/resources/${nextResource}?parentId=${id}&parentName=${name}`);
        }
    };

    const handleResourceModifications = (resourceData) => {
        navigate(`/edit/${resource}/${resourceData._id}?name=${resourceData.name}&description=${resourceData.description}&imageURL=${resourceData.imageURL}&duration=${resourceData.duration}`);
    };

    const getTitle = () => {
        return `${resource.charAt(0).toUpperCase() + resource.slice(1)} Management`;
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <div className='flex justify-between items-center p-4'>
                            <h1 className='text-4xl font-bold'>{getTitle()}</h1>
                            <div className='space-x-2 flex items-center'>
                                {resource === 'subject' || resource === 'quiz' ? (
                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                                        <InputLabel>Class</InputLabel>
                                        <Select
                                            value={selectedClass}
                                            onChange={handleClassChange}
                                            label="Class"
                                        >
                                            <MenuItem value="">
                                                <em>All Classes</em>
                                            </MenuItem>
                                            {classes.map((className) => (
                                                <MenuItem key={className} value={className}>
                                                    {className}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : null}
                                {resource === 'quiz' ? (
                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                                        <InputLabel>Subject</InputLabel>
                                        <Select
                                            value={selectedSubject}
                                            onChange={handleSubjectChange}
                                            label="Subject"
                                            disabled={!selectedClass} // Disable until a class is selected
                                        >
                                            <MenuItem value="">
                                                <em>All Subjects</em>
                                            </MenuItem>
                                            {subjects.map((subjectName) => (
                                                <MenuItem key={subjectName} value={subjectName}>
                                                    {subjectName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : null}
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    placeholder='Search...'
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    sx={{ height: '40px', backgroundColor: 'white' }}
                                />
                            </div>
                        </div>
                        <Table sx={{ width: '100%', marginBottom: 30, marginTop: 5 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='rounded-tl-lg' align='left'>
                                        S.No
                                    </StyledTableCell>
                                    {resource === 'subject' || resource === 'quiz' ? (
                                        <StyledTableCell align='left'>Class</StyledTableCell>
                                    ) : null}
                                    {resource === 'quiz' ? (
                                        <StyledTableCell align='left'>Subject</StyledTableCell>
                                    ) : null}

                                    <StyledTableCell align='left'>{resource.charAt(0).toUpperCase() + resource.slice(1)}</StyledTableCell>
                                    <StyledTableCell align='left'>Description</StyledTableCell>
                                    {resource === 'quiz' ? (
                                        <StyledTableCell align='left'>Duration</StyledTableCell>
                                    ) : null}
                                    <StyledTableCell align='left'>Updated</StyledTableCell>
                                    <StyledTableCell align='left'>Created</StyledTableCell>
                                    <StyledTableCell align='left'>Edit</StyledTableCell>
                                    <StyledTableCell align='left'>Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredResources.length > 0 &&
                                    filteredResources.map((item, index) => (
                                        <StyledTableRow key={item._id} sx={{ border: '1px solid gray' }}>
                                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                            {resource === 'subject' || resource === 'quiz' ? (
                                                <StyledTableCell align='left'>{item.class?.name || 'No class'}</StyledTableCell>
                                            ) : null}
                                            {resource === 'quiz' ? (
                                                <StyledTableCell align='left'>{item.subject?.name || 'No subject'}</StyledTableCell>
                                            ) : null}
                                            <StyledTableCell
                                                align='left'
                                                className='underline cursor-pointer'
                                                onClick={() => handleNameClick(item._id, item.name)}
                                            >
                                                {item.name}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>{item.description || 'No description'}</StyledTableCell>
                                            {resource === 'quiz' ? (
                                                <StyledTableCell align='left'>{item.duration || 'No duration'}</StyledTableCell>
                                            ) : null}
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(item.updatedAt)}</StyledTableCell>
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(item.createdAt)}</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => handleResourceModifications(item)}
                                                    sx={{ mr: 2 }}
                                                >
                                                    Edit
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <Button
                                                    variant='contained'
                                                    color='error'
                                                    onClick={() => handleResourceModifications(item)}
                                                >
                                                    Delete
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </Box>
            </Box>
        </>
    );
}

export default ResourceManagement;
