import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../Api/Constants';
import DrawerNav from '../Navigation/DrawerNav';

export default function ResourceDetails() {
    const { resource, id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const initialName = queryParams.get('name');
    const initialDescription = queryParams.get('description');
    const initialImageURL = queryParams.get('imageURL');
    const initialDuration = queryParams.get('duration') || 0;
    const initialTime = queryParams.get('time') || '';

    const [name, setName] = useState(initialName || '');
    const [description, setDescription] = useState(initialDescription || '');
    const [imageURL, setImageURL] = useState(initialImageURL || '');
    const [duration, setDuration] = useState(initialDuration);

    const handleSaveChanges = async () => {
        try {
            const bodyData = {
                name,
                description,
                imageURL,
                ...(resource === 'quiz' && duration && { duration })
            };

            const response = await fetch(`${BASE_URL}/api/v1/resources/${resource}/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyData),
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Resource updated successfully:', data.message);
                navigate(-1);
            } else {
                console.error('Error updating resource:', data.message);
            }
        } catch (error) {
            console.error('Error updating resource:', error);
        }
    };

    const handleDeleteResource = async () => {
        if (resource === 'quiz') {
            const result = await Swal.fire({
                title: `Delete ${resource.charAt(0).toUpperCase() + resource.slice(1)}?`,
                text: 'Are you sure you want to delete this resource?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
            });

            if (!result.isConfirmed) return;
        }

        try {
            const response = await fetch(`${BASE_URL}/api/v1/resources/${resource}/${id}`, {
                method: 'DELETE',
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Resource deleted successfully:', data.message);
                navigate(-1);
            } else {
                console.error('Error deleting resource:', data.message);
            }
        } catch (error) {
            console.error('Error deleting resource:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                    Edit {resource.charAt(0).toUpperCase() + resource.slice(1)}
                </Typography>
                <TextField
                    label='Name'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                />
                <TextField
                    label='Description'
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                />
                <TextField
                    label='Image URL'
                    value={imageURL}
                    onChange={(event) => setImageURL(event.target.value)}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                />
                {resource === 'quiz' && (
                    <TextField
                        label='Duration (in minutes)'
                        value={duration}
                        onChange={(event) => setDuration(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                )}
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant='contained' color='success' onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                    <Button variant='contained' color='error' onClick={handleDeleteResource}>
                        Delete {resource.charAt(0).toUpperCase() + resource.slice(1)}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
