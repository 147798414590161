import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { BASE_URL } from '../../Api/Constants';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DrawerNav from '../Navigation/DrawerNav';
import { SHOW_TOAST } from '../../Notifications/Toast';

export default function AddResource() {
    const { resource } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const parentId = queryParams.get('parentId');
    const parentName = queryParams.get('parentName');

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [imageURL, setImageURL] = useState('');

    const handleSaveChanges = async () => {
        if (!name || !description) {
            SHOW_TOAST('All fields are required', 'error');
            return;
        }

        try {
            const body = {
                name,
                description,
                imageURL,
            };

            switch (resource) {
                case 'subject':
                    body.classId = parentId;
                    break;
                case 'quiz':
                    body.subjectId = parentId;
                    break;
                default:
                    break;
            }

            const response = await fetch(`${BASE_URL}/api/v1/resources/${resource}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            const data = await response.json();

            if (response.ok) {
                SHOW_TOAST(`${resource.charAt(0).toUpperCase() + resource.slice(1)} added successfully`, 'success');
                setName('');
                setDescription('');
                setImageURL('');
                navigate(-1);
            } else {
                SHOW_TOAST(data.message || `Failed to add ${resource}`, 'error');
            }
        } catch (error) {
            SHOW_TOAST('Error: Failed to add resource', 'error');
            console.error('Error:', error);
        }
    };

    const getTitle = () => {
        return `Add New ${resource.charAt(0).toUpperCase() + resource.slice(1)}`;
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        {getTitle()}
                    </Typography>
                    <Typography variant='h6' sx={{ my: 2, color: '#4b4848' }}>
                        Parent: {parentName}
                    </Typography>
                    <TextField
                        label={`${resource.charAt(0).toUpperCase() + resource.slice(1)} Name`}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label={`${resource.charAt(0).toUpperCase() + resource.slice(1)} Description`}
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label={`${resource.charAt(0).toUpperCase() + resource.slice(1)} Image`}
                        value={imageURL}
                        onChange={(event) => setImageURL(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveChanges}>
                            Add {resource.charAt(0).toUpperCase() + resource.slice(1)}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
